/**
 * Site header
 */
.site-header {
    border-top: 0px solid $grey-color-dark;
    border-bottom: 0px solid $grey-color-light;
    height: $header_height;
    background: url(../img/annelsbach/DSC_8334_stitch_1920x200m.jpg) center center;
    background-size: cover;
    background-color: $header-bg-color;
    color: white;

    // Positioning context for the mobile navigation icon
    position: relative;

    @include media-query($on-smartphone) {
      background: url(../img/annelsbach/DSC_8334_stitch_450x100m.jpg) center center;
	  height: $header_height / 2;
    }
	
	@include media-query($on-palm) {
      background: url(../img/annelsbach/DSC_8334_stitch_800x100m.jpg) center center;
	  height: $header_height / 2;
    }
}

.site-title, {
    font-size: 26px;
    line-height: 26px;
    letter-spacing: -1px;
    font-weight: bold;
    color: white;
    margin-top: 10px;
    /* margin-right: 50px; */
    float: left;
    text-shadow: 0.03em 0.03em 0.08em #000;

    &,
    &:visited {
        color: white; // $grey-color-dark;
    }
    
    @include media-query($on-palm) {
      font-size: 22px;
      margin-right: 50px;
    }

    @include media-query($on-smartphone) {
      font-size: 20px;
      margin-right: 50px;
    }
}

.search-nav {
    position: relative ;
    top: 165px;
    line-height: 20px;
    font-weight: bold;
	z-index: 1001;
    /* text-shadow: 0.03em 0.03em 0.08em #fff; */

    .search-icon {
        display: block;
        float: right;
        width: 36px;
        height: 28px;
        line-height: 0;
        padding-top: 8px;
        text-align: center;
		border: 1px solid;
		border-radius: 4px;
		color: $grey-color-light;
		background-color: $grey-color-light;
		
        > svg {
            width: 41px;
            height: 29px;

			path {
                fill: $grey-color-dark;
            }
        }
		
		&:hover, &:active {
		color: $grey-color-dark;
		background-color: $grey-color-dark;
		> svg {
			path {
                fill: $grey-color-light;
            }
        }		
		}
    }
	
    @include media-query($on-palm) {
		position: absolute ;
		top: 10px;
		right: 10px;
		
		.search-icon {
			border: 1px solid;
			border-radius: 4px;
			color: $icon_color;
			background-color: transparent;

			> svg {
				width: 41px;
				height: 29px;

				path {
					fill: $icon_color;
				}
			}
		}
		
        &:hover .search-icon {
			background-color: $grey-color-light;
			border-color: $grey-color-light;

            > svg {
                path {
                    fill: $grey-color-dark;
                }
            }
        }			
	}
	
}	
	

.site-nav {
    position: relative ;
    line-height: 20px;
    font-weight: bold;
	z-index: 1000;
    /* text-shadow: 0.03em 0.03em 0.08em #fff; */

    .menu-icon {
        display: none;
    }

	
    .page-link, .page-link-active {
        /* background-color: $background-color; */
        color: $text-color;
        line-height: $base-line-height+0.5;
        border: 0;
		text-align: center;

        // Gaps between nav items, but not on the first one
        &:not(:first-child) {
            margin-left: 1px;
        }

    }
    ul {
       padding-top: $header_height - 32px;
       margin-left: 0;
    }

    li {
        position: relative;
        display: inline-block;
        width: 100px;
        background-color: $grey-color-light;
		
        text-decoration: none;
        /* list-style-type: none; */
		/* padding: 4px 4px 3px 4px; */
		margin-right: 0px;
    }
	
	li a {
	    text-decoration: none;
		display: block;
		text-align: center;
	}


    #nav_main li:hover {
        background-color: $grey-color-dark;
        text-decoration: none;
    }
	
    ul li a:hover, .page-link-active {
        background-color: $grey-color-dark;
        text-decoration: none;
        color: white;
    }

	

    @include media-query($on-palm) {
        position: absolute;
        top: 54px;
        right: 10px;
        border: 1px solid $icon-color;
        border-radius: 4px;
        text-align: right;
        
        .menu-icon {
            display: block;
            float: right;
            width: 36px;
            height: 26px;
            line-height: 0;
            padding-top: 10px;
            text-align: center;

            > svg {
                width: 18px;
                height: 15px;

                path {
                    fill: $icon-color;
                }
            }
        }
		
        &:hover .menu-icon {
			background-color: $grey-color-light;
			border-color: $grey-color-light;

            > svg {
                path {
                    fill: $grey-color-dark;
                }
            }
        }		
		
		
        .trigger {
            clear: both;
            display: none;
        }

		&:hover {
		background-color: $grey-color-light;
		border-color: $grey-color-light;
		}
		
        &:hover .trigger {
            display: block;
            padding-bottom: 5px;
			background-color: $grey-color-light;
			border-radius: 4px;
        }

        .page-link {
            display: block;
            padding: 10px 0px;

            &:not(:last-child) {
                margin-right: 0;
            }
        }
		
		ul {
		   padding: 0;
		   position: inherit;
		   margin-bottom: 0px;
		   border: 0;
		}
		
		li {
		   padding: 0px;
		   display: list-item;
		   list-style-type: none;
		   background-color: $grey-color-light;
		}

		#nav_main li:hover {
        background-color: $grey-color-light;
        text-decoration: none;
		border-radius: 5px;
        }
    }
}

/**
 * Breadcrumb Navigation
 */

.breadcrumbs {
    
	ul {
	   margin-left: 0; 
	
        li {
           
		   font-size: 0.9em;
		   display: inline-block; 
           list-style-type: none;
		}
    }
}	

/**
 * Post-Navigation
 */

.PageNavigation {
/*    display: block;
    overflow: hidden; */
	line-height: $base-line-height+0.5;
	border: 0;
	text-align: center;
		


	ul {
	   margin-left: 0;
       display: table;
       width: 100%;	   

       li {
          display: table-cell;
		  font-size: 0.9em;
		  line-height: 1.1em;
		  
          a {
            display: block;
		    text-align: left;
		  } 
		  .next {
		    text-align: right;
			margin-left: 5px;
		  }
		}
	}
}


	
/**
 * Site footer
 */
.site-footer {
    border-top: 1px solid $grey-color-light;
    padding: $spacing-unit 0;
}

.footer-heading {
    font-size: 18px;
    margin-bottom: $spacing-unit / 2;
}

.contact-list,
.social-media-list {
    list-style: none;
    margin-left: 0;
}

.footer-col-wrapper {
    font-size: 15px;
    color: $grey-color;
    margin-left: -$spacing-unit / 2;
    @extend %clearfix;
}

.footer-col {
    float: left;
    margin-bottom: $spacing-unit / 2;
    padding-left: $spacing-unit / 2;
}

.footer-col-1 {
    width: -webkit-calc(50% - (#{$spacing-unit} / 2));
    width:         calc(50% - (#{$spacing-unit} / 2));
}

.footer-col-2 {
    width: -webkit-calc(50% - (#{$spacing-unit} / 2));
    width:         calc(50% - (#{$spacing-unit} / 2));
}

.footer-col-3 {
    width: -webkit-calc(45% - (#{$spacing-unit} / 2));
    width:         calc(45% - (#{$spacing-unit} / 2));
}

@include media-query($on-laptop) {
    .footer-col-1,
    .footer-col-2 {
        width: -webkit-calc(50% - (#{$spacing-unit} / 2));
        width:         calc(50% - (#{$spacing-unit} / 2));
    }

    .footer-col-3 {
        width: -webkit-calc(100% - (#{$spacing-unit} / 2));
        width:         calc(100% - (#{$spacing-unit} / 2));
    }
}

@include media-query($on-palm) {
    .footer-col {
        float: none;
        width: -webkit-calc(100% - (#{$spacing-unit} / 2));
        width:         calc(100% - (#{$spacing-unit} / 2));
    }
}

/**
 * Page content
 */
.page-content {
    padding: $spacing-unit 0;
    p {
        /* text-align: justify; */
        -webkit-hyphens: auto;
        -moz-hyphens: auto;
        -ms-hyphens: auto;
        hyphens: auto;
    }
}

.page-heading {
    font-size: 20px;
}

/**
 * 2020-12-06 [JS]
 * Special background image for Christmas
 * class page-background and page-background:after are needed to use z-index to put the picture to the background.
 * Otherwise it would be on top of the thumbnails.
 * For now, it is only used on the start page index.html.
 */

 /** Uncomment this block, if it is to be used again
.page-background {}

.page-background:after {
	content : "";
	position: fixed;
	top: 10%;
	right: 0;
	background-image: url(../img/Weihnachtsbaum_2020.jpg);
	background-repeat: no-repeat;
	background-size: 100%; 
	width: 100%;
	height: 100%;
	opacity: 0.15;
	z-index: -1;
	}
**/  

/** Menu items on the page **/

ul.menu {
	list-style-type: none;
	
	li {
		@include media-query($on-palm) {
		  margin-left: -30px;
		  margin-right: 20px;
		  margin-bottom: 10px;
		}
	}
}

/** Formatting Standard Button **/

.button {
	background-color: $grey-color-light;
	border: none;
	border-radius: 5px;
	color: $text-color;
	font-weight: 600;
	padding: 5px 10px;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 16px;
	cursor: pointer;
	margin-bottom: 10px;
	width: 400px;

	@include media-query($on-palm) {
	  width: 400px;
	  margin: 0 0 10px 0;
	}

	@include media-query($on-smartphone) {
	  width: 100%;
	  margin: 0 0 10px 0;
	}
}

.button:hover {
	background-color: $grey-color-dark;
	color: white;
}		

/** Formatting special items **/

div {
	> hr {
		clear: both;
		color: $grey-color;
		background-color: $grey-color;
		height: 1px;
		border: 0;
		margin-bottom: 1em;
	}
}

del {
	background-color: $update-color_del;	
}

ins {
	background-color: $update-color_ins;	
}

.update-new {
	font-weight: bold;
	padding: 2px 2px;
	border-radius: 3px;
	background-color: $new-color;
}


/** Task list formatting **/

ul.task {
	list-style-type: none;
	margin-left: 0px;
}
li.task-active {
	background-color: $task-color-active;
}
li.task-ready {
	background-color: $task-color-ready;
}
li.task-wait {
	background-color: $task-color-wait;
}
li.task-next {
	background-color: $task-color-next;
}

/** Link-Inline-Action-Buttons **/

a.btn-action {
	background-color: $button-bgcolor;
	color: $button-color;
	padding: 2px 10px;
	border-radius: 5px;
	font-weight: 400;
	text-align: center;
	text-decoration: none;
}
a.btn-action:hover {
	background-color: $grey-color-dark;
	color: white;
}


/** Formatting post lists in general **/

.post-list {
    margin-left: 0;
    list-style: none;

	
    > li {
        margin-bottom: 1em;
		page-break-inside: avoid;
		> hr {
				clear: both;
				color: $grey-color;
				background-color: $grey-color;
				height: 1px;
				border: 0;
		}		


    }
}

.post-item {
    margin-bottom: 1em;
}

.post-meta {
    font-size: $small-font-size;
    color: $grey-color;
}

.post-link {
    display: block;
    font-size: 24px;
}


/** Formatting post lists in archive **/

.archive {
		> h3 {
				background-color: $grey-color;
				color:	white;
		}
}

summary.archive {
	font-size: 20px;
	}

.div-bgcolor {
    background: $div-bgcolor;
}


input#search-query {
    font-size: 1.1em;
    margin-bottom: 0.6em;
}


.rss-subscribe {
    margin-top: $spacing-unit * 2;
}

.important {
	background-color: $alarm-bg-color;
	color: $alarm-color;
	text-align: center;
}


/**
 * Posts
 */
.post-header {
    margin-bottom: $spacing-unit;
}

.post-title {
    font-size: 28px;  // vorher 36px
    // letter-spacing: -1px;
    line-height: 1;

    @include media-query($on-laptop) {
        font-size: 32px;
    }
}

.post-content {
    margin-bottom: $spacing-unit;

    p {
        /* text-align: justify; */
        -webkit-hyphens: auto;
        -moz-hyphens: auto;
        -ms-hyphens: auto;
        hyphens: auto;
	}


    h1, h2 {
        font-size: 28px;
		/* Neue Zeile erzwingen */
        clear: both;

        @include media-query($on-laptop) {
            font-size: 26px;
			/* Neue Zeile erzwingen */
            clear: both;
        }
    }

    h3 {
        font-size: 22px;

        @include media-query($on-laptop) {
            font-size: 20px;
        }
    }

    h4 {
        font-size: 18px;

        @include media-query($on-laptop) {
            font-size: 16px;
        }
    }

	del {
	background-color: $update-color_del;	
	}

	ins {
	background-color: $update-color_ins;	
	}

}

strong {
      font-weight: bold;
}

img {    /* Generelle Formatierung der formatierten Fotos */
	border-radius: 3px;
	vertical-align: text-top;
	margin: 0.2em 0 0 0;

}

.inlinebutton {
	border-radius: 0;
	vertical-align: text-bottom;
	margin: 0 0 0 0;
} 



.image-wrapper{
  max-width:100%;
  height:auto;
  position: relative;
  display:block;
  margin:0 auto;
}

.image-wrapper img{
  max-width:100% !important;
  height:auto;
  display:block;
}

.image-caption{
        margin: 0.2em 0 0 0;
        font-style: italic;
        font-size: 100%;
}		

picture {
		max-width:100% !important;
        height: auto;
}

figure {
       position: relative;
	   display: block;
	   margin-bottom: 1em;
}

figure img {
  display: block;
  width: 100%;
}
 
figcaption {
}


.fleft, .thumbnail {
        /* Sachen an denen der Text vorbeifliessen soll werden diese Klassen zugewiesen. */
        float: left;
        margin: 0.15em 1em 0.6em 0.1em;
        padding: 0px; /* Man sollte einen schoenen Abstand waehlen sonst kleben die Sachen so am Text. */
        border: 0;
}

.fright {
        float: right;
        margin: 0.15em 0 0.6em 1em;
        padding: 0px;
        border: 0;
}

/* Zugehoerige Klassen fuer die Bildunterschriften */
.fleft dt { margin-bottom: 0; }
.fleft dd {
        margin: 0;
        font-style: italic;
        font-size: 90%;
        text-align: left;
} /* verhindert das Einruecken und den Abstand nach oben */


/* Zugehoerige Klassen fuer die Bildunterschriften etc. */
.fright dt { margin-bottom: 0; }
.fright dd {
        margin: 0;
        font-style: italic;
        font-size: 90%;
        text-align: left;
} /* verhindert das Einruecken und den Abstand nach oben */

abbr:active::after{
    border : none;
    content : " (" attr(title) ")";
}

#print_glossar {
        display: none;
}
#print_footer {
        display: none;
}

.print_footer {
        display: none;
}

.file-list {
       display: table-row;
       margin: 0;
}

li a img {
   margin: 0;
}



table {
    border-collapse: collapse;
    width: 100%;
}

td, th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
}

tr.list:nth-child(even) {
    background-color: #dddddd;
}


@media only screen and (max-width: 28em) {
	.thumbnail {
		/* display: none; */
		/* width: 5.25em; */
        margin: 0.15em 0.5em 0.5em 0.1em;
		
		img {
		margin-bottom: 0;
		}
	}
	figure.blog-full {
		width: 100%;
		overflow: hidden;
	}

	.fright, .fleft {
		width: 100%;
		clear: both;
		margin: 0;
	}
	
	figure.fleft, figure.fright {
		width: 100%;
		clear: both;
		margin-bottom: 1em;
	}

	figure.fleft {
		margin-right: 0.6em;
	}
	
	figure.fright {
		margin-left: 0.6em;
	}
	
}




@media only screen and (max-width: 30em) {

	figure.blog-full {
		width: 100%;
		overflow: hidden;
	}
	.fright, .fleft {
		width: 100%;
		clear: both;
		margin: 0;
	}
	
	figure.fleft, figure.fright {
		width: 100%;
		clear: both;
		margin-bottom: 1em;
	}
	
	figure.fleft {
		margin-right: 0.6em;
	}
	
	figure.fright {
		margin-left: 0.6em;
	}
	
	ul.task {
		padding-right: 0%;
	}

}

@media only screen and (min-width: 30em) {
	figure.blog-full {
		width: 100%;
		overflow: hidden;
	}
	figure.fleft{
		width: 49%;
	}
	figure.fright{
		width: 49%;
	}
	
	ul.task {
		padding-right: 50%;
	}

}

@media only screen and (min-width: 1024px) {
	figure.blog-full {
		width: 100%;
		overflow: hidden;
	}
	figure.fleft{
		width: 33.333333333%;
	}
	
	figure.fright{
		width: 33.333333333%;
	}
	
	ul.task {
		padding-right: 34.333333333%;
	}
}
/* prettyPhoto styling */
.pp_middle {
    z-index: -1;
}


/* prettyPhoto styling for small screens  - below 767px width */
@media only screen and (max-width: 767px) {
.pp_gallery { display: none!important; }
#pp_full_res img { 
    width: 100%!important;
    height: auto!important;}
a.pp_close { right: 10px!important; top: 10px!important; z-index: 200;}
}




@media print{

	.site-header {
		background: none;
		background-color: $header-bg-color;
		height: $header_height/4;
	}

	h1,h2,h3,h4,h5,h6 {
		page-break-after: avoid;
		/* Kein Seitenumbruch direkt nach Überschriften */
	}

	post-content {
		orphans: 5;
		widows: 5;
	}
	
	figure.blog-full {
			width: 100%;
			page-break-inside: avoid;
	}

	figure.fleft{
			width: 50%;
			page-break-inside: avoid;
	}

	figure.fright{
			width: 50%;
			page-break-inside: avoid;
	}


	#bottom_menu {
			display: none;
	}

	#media_only {
			display: none;
	}

	#media_text {
			display: none;
	}

	#site_nav {
			display: none;
	}

	.site-nav {
			display: none;
	}
	 
	nav.breadcrumbs {
			display: none;
	}

	.PageNavigation ul li a {
			display: none;
	}

	.navitext {
			display: none;
	}

	.navitext.active {
			display: none;
	}

	.search-nav .search-icon {
		display: none;
	}

	  a:link {
			text-decoration: none;
			color: black;
	}

	  a:visited {
			text-decoration: none;
			color: black;
	}

	  a:active {
			text-decoration: none;
			color: black;
	}
	
	.site-footer {
			display: none;
	}
	.print_footer {
		display: block;
		text-align: center;
	}
	abbr {
        text-decoration: none;   
    }
    abbr:after{
        border-bottom: 0px;
        content : " (" attr(title) ")";
    }
}