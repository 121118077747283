@charset "utf-8";



// Our variables
$base-font-family: Helvetica, Arial, sans-serif;
$base-font-size:   16px;
$small-font-size:  $base-font-size * 0.875;
$base-line-height: 1.5;

$spacing-unit:     30px;

$text-color:       #111;
$background-color: #fdfdfd;
$header-bg-color:  #609ae6;
$brand-color:      #2a7ae2;

$grey-color:       #828282;
$grey-color-light: lighten($grey-color, 40%);
$grey-color-dark:  darken($grey-color, 25%);

$icon_color:       white;

$div-bgcolor:      rgba(0, 135, 255, 0.24);

// Text background color for new or updated text
$new-color:        rgb(148, 255, 0);
$update-color:     rgba(148, 255, 0, 0.25);
$update-color_del: rgba(255, 0, 0, 0.25);
$update-color_ins: rgba(148, 255, 0, 0.25);

// Text background color for tasks
$task-color-ready:  #d4fd94;
$task-color-wait:   #d4fd94;
$task-color-next:   #fdfd94;
$task-color-active: #ffb5b5;

$button-bgcolor:    blue;
$button-color:      white;  

// Text color for inportant notice
$alarm-bg-color:   red;
$alarm-color:      white;

// Text color for blockquotes
$blockquote-bg-col:   $grey-color-light;
$blockqoute-bar-col:  $header-bg-color;
$blockqoute-txt-col:  $grey-color-dark;


// Height of the header
$header_height:    200px;

// Width of the content area
$content-width:    1280px + $spacing-unit * 2 ;

$on-smartphone:    450px; 
$on-palm:          700px;
$on-laptop:        800px;


// Using media queries with like this:
// @include media-query($on-palm) {
//     .wrapper {
//         padding-right: $spacing-unit / 2;
//         padding-left: $spacing-unit / 2;
//     }
// }
@mixin media-query($device) {
    @media screen and (max-width: $device) {
        @content;
    }
}



// Import partials from `sass_dir` (defaults to `_sass`)
@import
        "base",
        "layout",
        "syntax-highlighting",
        "events",
		"svg-icons"
;
