/**
 * Event-list format
 */
.event-list {
   margin-left: 0px;
}
.event-list li {
   font-size: $base-font-size;
   list-style-type: none;
}
.event-list ul {
   margin: 0;
}
.event-date {
   background-color: $grey-color-light;
   padding-left: 5px;
   list-style-type: none;
   text-decoration: none;
   font-weight: bold;
}
.event-item {
   text-decoration: none;
   font-weight: normal;
   padding-left: 5px;
}

.event-name, .event-location {
   margin-top: 5px;
   margin-bottom: 5px;
   list-style-type: none;
   text-decoration: none;
   font-weight: bold;
}

.event-location {
   font-weight: normal;
}

.event-host {
   font-size: $small-font-size;
   margin-top: 5px;
   font-style:italic;
}

.event-month {
       list-style-type: none;
       font-weight: bold;
	   margin-top: 10px;
       margin-bottom: 5px;
}

.event {
   border: 2px solid $grey-color-light;
   margin-bottom: 20px;
   margin-top: 5px;
}

.event-update,
.event-new {
	font-weight: normal;
	padding: 1px 5px;
	margin: 0px 10px;
	border-radius: 3px;
}

.event-update {
	background-color: $update-color;
}

.event-new {
	background-color: $new-color;
}

.event del {
	background-color: $update-color_del;	
}

.event ins {
	background-color: $update-color_ins;	
}


.event-list li .event-update-info, .event-list li .event-publish-info {
	font-size: $small-font-size;
	margin-top: 5px;
}




/**
.event {
   border-top: 1px solid $grey-color;
   border-bottom: 1px solid $grey-color;
   padding: 5px 0 5px 0;
   margin-bottom: -1px;
   margin-top: -1px;
}
**/